import KeenSlider from 'keen-slider'
import 'keen-slider/keen-slider.min.css'

// function navigation(slider) {
//   let wrapper, dots, arrowLeft, arrowRight

//   function markup(remove) {
//     wrapperMarkup(remove)
//     dotMarkup(remove)
//   }

//   function removeElement(elment) {
//     elment.parentNode.removeChild(elment)
//   }
//   function createDiv(className) {
//     var div = document.createElement("div")
//     var classNames = className.split(" ")
//     classNames.forEach((name) => div.classList.add(name))
//     return div
//   }


//   function wrapperMarkup(remove) {
//     if (remove) {
//       var parent = wrapper.parentNode
//       while (wrapper.firstChild)
//         parent.insertBefore(wrapper.firstChild, wrapper)
//       removeElement(wrapper)
//       return
//     }
//     wrapper = createDiv("RS-social-navigation-wrapper")
//     slider.container.parentNode.appendChild(wrapper)
//     wrapper.appendChild(slider.container)
//   }

//   function dotMarkup(remove) {
//     if (remove) {
//       removeElement(dots)
//       return
//     }
//     dots = createDiv("RS-social-navigation-dots")
//     slider.track.details.slides.forEach((_e, idx) => {
//       var dot = createDiv("RS-social-navigation-dot")
//       dot.addEventListener("click", () => slider.moveToIdx(idx))
//       dots.appendChild(dot)
//     })
//     wrapper.appendChild(dots)
//   }

//   function updateClasses() {
//     var slide = slider.track.details.rel
//     Array.from(dots.children).forEach(function (dot, idx) {
//       idx === slide
//         ? dot.classList.add("RS-social-navigation-dot--active")
//         : dot.classList.remove("RS-social-navigation-dot--active")
//     })
//   }

//   slider.on("created", () => {
//     markup()
//     updateClasses()
//   })
//   slider.on("optionsChanged", () => {
//     console.log(2)
//     markup(true)
//     markup()
//     updateClasses()
//   })
//   slider.on("slideChanged", () => {
//     updateClasses()
//   })
//   slider.on("destroyed", () => {
//     markup(true)
//   })
// }


// export const initSocialSlider = () => {
//   var slider = new KeenSlider('#RS-social-slider', {
//       loop: true,
//       slides: {
//         spacing: 48
//       },
//       created: () => {
//       }
//     },
//     [
//       (slider) => {
//         let timeout
//         let mouseOver = false
//         function clearNextTimeout() {
//           clearTimeout(timeout)
//         }
//         function nextTimeout() {
//           clearTimeout(timeout)
//           if (mouseOver) return
//           timeout = setTimeout(() => {
//             slider.next()
//           }, 15000)
//         }
//         slider.on("created", () => {
//           slider.container.addEventListener("mouseover", () => {
//             mouseOver = true
//             clearNextTimeout()
//           })
//           slider.container.addEventListener("mouseout", () => {
//             mouseOver = false
//             nextTimeout()
//           })
//           nextTimeout()
//         })
//         slider.on("dragStarted", clearNextTimeout)
//         slider.on("animationEnded", nextTimeout)
//         slider.on("updated", nextTimeout)
//       },
//       navigation
//     ]
//   )
// };


export const initMarqueeSlider = () => {
  var animation = { duration: 20000, easing: (t) => t }
  var slider = new KeenSlider("#RS-marquee", {
    slides: {
      perView: 8,
    },
    loop: true,
    renderMode: "performance",
    drag: true,
    breakpoints: {
      '(max-width: 1280px)': {
        slides: {
          perView:7,
        }
      },
      '(max-width: 768px)': {
        slides: {
          perView:6,
        }
      },
      '(max-width: 594px)': {
        slides: {
          perView:4,
        }
      },
      '(max-width: 420px)': {
        slides: {
          perView:3,
        }
      },
    },
    created(s) {
      s.moveToIdx(5, true, animation)
    },
    updated(s) {
      s.moveToIdx(s.track.details.abs + 5, true, animation)
    },
    animationEnded(s) {
      s.moveToIdx(s.track.details.abs + 5, true, animation)
    },
  })
};


// export const initWordSlider = () => {
//   var animation = { duration: 5000, easing: (t) => t }
//   var slider2 = new KeenSlider("#RS-intro__word-slider", {
//     loop: true,
//     renderMode: "performance",
//     drag: false,
//     vertical: true,
//     created(s) {
//       s.moveToIdx(5, true, animation)
//     },
//     updated(s) {
//       s.moveToIdx(s.track.details.abs + 5, true, animation)
//     },
//     animationEnded(s) {
//       s.moveToIdx(s.track.details.abs + 5, true, animation)
//     },
//   })
// };